import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { json } from "./json";
import { themeJson } from "./theme";
import axios from "axios";

function SurveyComponent() {
    const survey = new Model(json);
    let formData = new FormData();
    // You can delete the line below if you do not use a customized theme
    survey.applyTheme(themeJson);
    survey.onComplete.add((sender, options) => {
        console.log(JSON.stringify(sender.data, null, 3));
        formData.append('memberName', (sender.data.question1).toUpperCase());
        formData.append('memberMobileNo', sender.data.question2);
        
        let Trainer = ''
        switch (sender.data.question3) {
          case "Item 1":
            Trainer = 'YOUNUS'
            break;
          case "Item 2":
            Trainer = 'RAJU'
            break;
          case "Item 3":
            Trainer = 'DHANYA'
            break;
          case "Item 4":
            Trainer = 'PAVAN'
            break;
          case "Item 5":
            Trainer = 'AMAL'
            break;
          case "Item 6":
            Trainer = 'ANEES'
            break;
          default:
            Trainer = 'Trainer Not Selected'
            break;
        }
        formData.append('trainerName', Trainer)
        formData.append('trainerRating', sender.data.question4)
        formData.append('feedback', sender.data.question5)
        if(
            sender &&
            sender.data &&
            sender.data.question1 &&
            sender.data.question2 &&
            Trainer &&
            sender.data.question4
        ){
            axios({
                method: 'post',
                url: 'https://admin.tarzanfitness.in/server/survey.php',
                data: formData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
        }
    });
    return (<Survey model={survey} />);
}

export default SurveyComponent;
